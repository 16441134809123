<template>
    <div :class="containerClass" :data-theme="colorScheme" @click="onDocumentClick($event)">
    </div>
</template>

<script>
</script>

<style lang="scss">
@import "App.scss";
</style>
