import { createApp, h } from 'vue';

import App from './App.vue';
import PrimeVue from 'primevue/config';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';


const app = createApp({
    computed: {
        ViewComponent () {
			switch (this.$route.path) {
				default:
					return App;
			}
		}
    },
    render () { return h(this.ViewComponent) }
});

app.use(PrimeVue, { ripple: true });


app.mount('#app');